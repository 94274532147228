<template>
  <div class="timeaxis">
    <div
      class="timeaxis-line"
      @mouseover="isShow = true"
      @mouseleave="isShow = false"
    >
      <div class="hengxian">
        <!-- <div style="width: 1200px;height: 2px;background: #83B5FF;border-radius: 1px;"></div> -->
        <!-- <img :src="fontAssetsURL(`info/矩形 1652.png`)" alt="" v-if="!showme" /> -->
        <!-- <img :src="fontAssetsURL(`info/矩形 1652@2x.png`)" alt="" v-if="showme" /> -->
        <img :src="fontAssetsURL(`info/横线.png`)" alt="" />
      </div>

      <!-- <div class="pre" @click="goPre()">
        <img :src="fontAssetsURL(`info/组 112.png`)" alt="" v-if="!showme && isShow && isnull" />
        <img :src="fontAssetsURL(`info/组 112@2x.png`)" alt="" v-if="showme && isShow && isnull" />
        <img
          class="imgreverse"
          :src="fontAssetsURL(`info/组 112 拷贝.png`)"
          alt=""
          v-if="!showme && isShow && !isnull"
        />
        <img
          class="imgreverse"
          :src="fontAssetsURL(`info/组 112 拷贝@2x.png`)"
          alt=""
          v-if="showme && isShow && !isnull"
        />
      </div> -->

      <div class="line">
        <div class="line-z" ref="lineZ" name="全部的线">
          <div
            class="line-z-item"
            v-for="(item, index) in localObjArr"
            :key="item.id"
            @click="clickme(index)"
            :class="{ active: currentIdx === index }"
          >
            <div class="yuanquan"></div>
            <!-- <div class="hengxian"></div> -->
            <div class="time">
              {{ item.date }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div @click="goNext()" class="next">
        <img :src="fontAssetsURL(`info/组 112 拷贝.png`)" alt="" v-if="!showme && isShow" />
        <img :src="fontAssetsURL(`info/组 112 拷贝@2x.png`)" alt="" v-if="showme && isShow" />
      </div> -->
    </div>
    <div class="content">
      <div
        class="content-item"
        v-for="items in localObjArr[currentIdx].content"
        :key="items.id"
      >
        <div class="month">
          {{ items.month }}{{ (danwei = items.month ? "月" : "") }}
        </div>
        <div class="content-list">
          <div v-if="items.content.length < 2">
            <div
              class="content-list-item one"
              v-for="(item, index) of items.content"
              :key="index"
            >
              {{ item.content }}
            </div>
          </div>
          <div v-else class="more">
            <div
              class="content-list-item more"
              v-for="(item, index) of items.content"
              :key="index"
            >
              {{ item.content }}
            </div>
          </div>

          <!-- <div class="content-list-item" v-for="(item, index) of items.content" :key="index">
            <div class="one" v-if="items.content.length < 2">
              {{ item.content }}
            </div>
            <div class="more" v-else>{{ item.content }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.localObjArr[this.currentIdx].content);
  },
  data() {
    return {
      isShow: false, //左右切换按钮显示
      isnull: 1, //是否没有了切换灰色图片
      currentIdx: 1, //当前选择项
      localObjArr: this.obj, //传递的数组拷贝
      addVal: 0, //轮播图前进距离叠加值
      danwei: "日",
      preTime: Date.now(), //节流用
    };
  },
  props: {
    showme: {
      type: Number,
      default: 0,
    },
    obj: {
      type: Array,
      default: () => {
        return [
          {
            id: 0,
            date: "2020年2月",
            content: [
              {
                id: 0,
                day: 7,
                content: "重要的日子这样的人",
              },
            ],
          },
        ];
      },
    },
  },
  methods: {
    //点击时间轴
    clickme(idx) {
      this.currentIdx = idx;
    },
    //往前
    goPre() {
      console.log(11);
      if (Date.now() - this.preTime < 400) {
        return;
      }
      this.preTime = Date.now();

      const domz = document.querySelector(".line-z").getBoundingClientRect()
          .left,
        dom = document.querySelector(".line").getBoundingClientRect().left;

      if (this.currentIdx > 0) {
        this.currentIdx--;
        if (domz < dom) {
          this.addVal += 126.67;
          this.$refs.lineZ.style.transform = `translate(${this.addVal}px)`;
        }
      } else {
        this.isnull = 1;
      }
    },
    //往后
    goNext() {
      if (Date.now() - this.preTime < 400) {
        return;
      }
      this.preTime = Date.now();

      const domz = document.querySelector(".line-z").getBoundingClientRect()
          .right,
        dom = document.querySelector(".line").getBoundingClientRect().right;

      if (this.currentIdx < this.localObjArr.length - 1) {
        this.currentIdx++;
        this.isnull = 0;
        if (domz > dom + 126.67) {
          this.addVal -= 126.67;
          this.$refs.lineZ.style.transform = `translate(${this.addVal}px)`;
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './timeaxis.styl';
</style>
